import { Layout } from 'antd'
import { Outlet, useNavigate } from 'react-router-dom'
import { LayoutFooter } from './LayoutFooter'

const { Header, Content } = Layout

export const PublicLayout: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Layout hasSider style={{ minHeight: '100vh' }}>
      <Layout style={{ background: '#f8f8f2' }}>
        <Header
          style={{ paddingLeft: 20, paddingRight: 20, background: '#fff' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              height: '100%',
              alignItems: 'center',
            }}
          >
            <img
              alt="My Easy Sign"
              src="/assets/logo_mes.png"
              style={{
                float: 'left',
                height: 46,
                cursor: 'pointer',
              }}
              onClick={() => navigate('/')}
            />
          </div>
        </Header>

        <Content
          style={{
            margin: '0',
            paddingTop: 30,
            background: '#f8f8f2',
            maxWidth: '1000px',
            width: '100%',
            paddingRight: 10,
            paddingLeft: 10,
            marginRight: 'auto',
            marginLeft: 'auto',
          }}
        >
          <Outlet />
        </Content>

        <LayoutFooter />
      </Layout>
    </Layout>
  )
}

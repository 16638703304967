import { useEffect, useState, PropsWithChildren } from 'react'
import { Role } from '../../../models/Role'
import { enumFromStringValue, Permission } from '../../../helpers/permissions'
import { useAuth } from '../../../contexts/Auth'
import { User } from '../../../models/User'

export interface HasAccessProps {
  roles?: Role | Role[]
  permissions?: Permission | Permission[]
  isLoading?: React.ReactNode
  renderAuthFailed?: React.ReactNode
}

const HasAccess = ({
  roles,
  permissions,
  isLoading,
  renderAuthFailed,
  children,
}: PropsWithChildren<HasAccessProps>) => {
  const { currentUser } = useAuth()
  const [hasAccess, setHasAccess] = useState<boolean>(false)
  const [checking, setChecking] = useState<boolean>(false)

  useEffect(() => {
    if (!currentUser) {
      console.error(
        'No user provided! You must set user to perfom access check'
      )
      return
    }

    setChecking(true)

    // Support user has all access
    if (userHasRoles(currentUser, [])) {
      setHasAccess(true)
    }

    // role check
    if (roles) {
      const rolesToCheck = Array.isArray(roles) ? roles : [roles]
      if (
        rolesToCheck &&
        currentUser.roles &&
        currentUser.roles.length > 0 &&
        userHasRoles(currentUser, rolesToCheck)
      ) {
        setHasAccess(true)
      }
    }

    // permission check
    if (permissions) {
      const permissionsToCheck = Array.isArray(permissions)
        ? permissions
        : [permissions]
      if (
        permissionsToCheck &&
        currentUser.permissions &&
        currentUser.permissions.length > 0 &&
        userHasPermissions(currentUser, permissionsToCheck)
      ) {
        setHasAccess(true)
      }
    }

    setChecking(false)
  }, [roles, permissions, currentUser])

  if (!hasAccess && checking) {
    return <>{isLoading}</>
  }

  if (hasAccess) {
    return <>{children}</>
  }

  if (renderAuthFailed) {
    return <>{renderAuthFailed}</>
  }

  return <></>
}

export default HasAccess

export const useHasAccess = () => {
  const { currentUser } = useAuth()

  const hasAccess = (permissions: Permission | Permission[]): boolean => {
    // TODO:
    // Bypass all hasAccess check
    return true

    if (!currentUser) {
      console.error(
        'No user provided! You must set user to perfom access check'
      )
      return false
    }

    // // Support has all access
    // if (userHasRoles(currentUser, [])) return true

    // if (!currentUser.permissions || currentUser.permissions.length === 0) {
    //   console.error("The current user doesn't have permissions")
    //   return false
    // }

    // const permissionsToCheck = Array.isArray(permissions)
    //   ? permissions
    //   : [permissions]

    // if (!permissionsToCheck || permissionsToCheck.length === 0) {
    //   console.error(
    //     'No permissions to check! Please provide at least one permission'
    //   )
    //   return false
    // }

    // // Permissions check
    // return userHasPermissions(currentUser, permissionsToCheck)
  }

  return { hasAccess }
}

const userHasPermissions = (user: User, permissions: Permission[]): boolean =>
  user.permissions.filter((permission: string) => {
    const alelaPermission = enumFromStringValue(Permission, permission)
    return (
      alelaPermission !== undefined && permissions.includes(alelaPermission)
    )
  }).length > 0

const userHasRoles = (user: User, roles: Role[]): boolean => {
  // Support has all access
  if (user.roles.map((r) => r.name).includes('support')) return true

  const roleNames = roles.map((r) => r.name)
  return (
    user.roles.filter((role: Role) => roleNames.includes(role.name)).length > 0
  )
}

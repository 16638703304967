import { SortOrder, SorterResult, Key } from 'antd/es/table/interface'

interface SortingData {
  columnKey?: Key
  order?: SortOrder
}

const extractSortDataFromSorterResult = <T>(
  sorter: SorterResult<T> | SorterResult<T>[]
): SortingData => {
  const sort = (
    Array.isArray(sorter) && sorter.length > 0 ? sorter[0] : sorter
  ) as SorterResult<T>

  return {
    columnKey: sort.columnKey,
    order: sort.order,
  }
}

export { extractSortDataFromSorterResult }

import React from 'react'
import { Link } from 'react-router-dom'
import {
  ConditionalLinkWrapperProps,
  ConditionalWrapperProps,
} from './ConditionalWrappers.types'

const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}) => (condition ? wrapper(children) : children)

const ConditionalLinkWrapper: React.FC<ConditionalLinkWrapperProps> = ({
  condition,
  to,
  onClick,
  children,
}) =>
  condition ? (
    <Link to={to} onClick={onClick}>
      {children}
    </Link>
  ) : (
    children
  )

export { ConditionalLinkWrapper, ConditionalWrapper }

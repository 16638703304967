import { Select, Space } from 'antd'
import { useEffect, useState } from 'react'
import { getLoggedUserOrganizations } from '../../helpers/AuthHelper'
import { Organization } from '../../models/Organization'
import { useAuth } from '../../contexts/Auth'
import { useNavigate, useParams } from 'react-router-dom'
import { TeamOutlined, UserOutlined } from '@ant-design/icons'

interface Props {}

const Selector = ({}: Props) => {
  const navigate = useNavigate()
  const { organizations } = useAuth()
  const { account } = useParams<{ account: string }>()

  return (
    <Space
      direction="vertical"
      style={{ width: '100%', paddingRight: 5, paddingLeft: 5 }}
    >
      Account
      <Select
        style={{ width: '100%' }}
        value={account}
        onChange={(newValue) => {
          navigate(`/${newValue}/signature`)
        }}
        options={[
          {
            label: <span>Personal</span>,
            value: 'personal',
            options: organizations
              .filter((o) => o.isPersonal)
              .map((o) => ({ label: renderPersonal(o), value: o.slug })),
          },
          {
            label: <span>Organizations</span>,
            value: 'organizations',
            options: organizations
              .filter((o) => !o.isPersonal)
              .map((o) => ({ label: renderOrganization(o), value: o.slug })),
          },
        ]}
      />
    </Space>
  )
}

const renderPersonal = (org: Organization) => (
  <Space direction="horizontal">
    <UserOutlined />
    {org.name}
  </Space>
)

const renderOrganization = (org: Organization) => (
  <Space direction="horizontal">
    <TeamOutlined />
    {org.name}
  </Space>
)

type SelectorType = { Selector: typeof Selector }

export { Selector, type SelectorType }

import { Alert, Button, Divider, Form, Input, Space, Typography } from 'antd'
import { useState } from 'react'
import i18n from '../../i18n/setupLocalization'
import { useAuth } from '../../contexts/Auth'
import { RegisterData } from '../../models/AuthModel'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import {
  capsRule,
  containsNumberRule,
  containsSymbolRule,
  emailRule,
  lowercaseRule,
  minLengthRule,
  requiredRule,
} from '../../helpers/rules'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { CSRF_COOKE_URL } from '../../helpers/urls'

const { Title, Text } = Typography

export function Register() {
  const { register } = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const handleRegister = (values: RegisterData) => {
    setIsLoading(true)
    setError(undefined)
    setIsSuccess(false)

    axios.get(CSRF_COOKE_URL).then(() => {
      register(values)
        .then(() => {
          setIsSuccess(true)
        })
        .catch((err) => {
          //TODO: Handle
        })
        .finally(() => {
          setIsLoading(false)
        })
    })
  }

  return (
    <Space
      direction="vertical"
      style={{ padding: 20, textAlign: 'center' }}
      size={20}
    >
      <img src="/assets/logo_mes.png" width={300} />
      <Title style={{ margin: 0, marginBottom: '1rem' }}>
        {i18n.t(LocalizationKeys.Page.Register.Title)}
      </Title>
      {isSuccess === false && (
        <>
          <div style={{ textAlign: 'center', marginTop: 10 }}>
            {error && (
              <Alert
                style={{ marginBottom: 25 }}
                message={error}
                type="error"
              />
            )}
          </div>
          <Form onFinish={handleRegister}>
            <Form.Item name="firstName" rules={[]}>
              <Input
                placeholder={i18n.t(LocalizationKeys.Misc.Form.FirstName)}
                autoComplete="first_name"
              />
            </Form.Item>
            <Form.Item name="lastName" rules={[]}>
              <Input
                placeholder={i18n.t(LocalizationKeys.Misc.Form.LastName)}
                autoComplete="first_name"
              />
            </Form.Item>
            <Form.Item name="username" rules={[requiredRule()]}>
              <Input
                placeholder={i18n.t(LocalizationKeys.Misc.Form.Username) + ' *'}
                autoComplete="username"
              />
            </Form.Item>
            <Form.Item name="email" rules={[requiredRule(), emailRule()]}>
              <Input
                placeholder={i18n.t(LocalizationKeys.Misc.Form.Email) + ' *'}
                autoComplete="email"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                requiredRule(),
                minLengthRule(12),
                lowercaseRule(),
                capsRule(),
                containsNumberRule(),
                containsSymbolRule(),
              ]}
            >
              <Input.Password
                placeholder={i18n.t(LocalizationKeys.Misc.Form.Password) + ' *'}
                autoComplete="new-password"
              />
            </Form.Item>
            <Form.Item
              name="passwordConfirmation"
              rules={[
                requiredRule(),
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error('The passwords do not match!')
                    )
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder={
                  i18n.t(LocalizationKeys.Misc.Form.PasswordConfirmation) + ' *'
                }
                autoComplete="new-password"
              />
            </Form.Item>
            <Form.Item style={{ marginBottom: 15 }}>
              <Button
                className="w-full"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                {i18n.t(LocalizationKeys.Page.Register.Submit)}
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
      {isSuccess === true && (
        <>
          <Alert
            style={{ marginBottom: 25 }}
            type="success"
            message={
              <>
                <Text>You are registered! You can now proceed to login</Text>
                <br />
                <Link to={'/auth/login'}>Go to login</Link>
              </>
            }
          />
        </>
      )}
      <Divider />
      <Link to={'/auth/login'}>Already have an account ? Login</Link>
    </Space>
  )
}

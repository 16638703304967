export interface AuthModel {
  access_token: string
  expires_in: string
  refresh_token: string
  token_type: string
}

export const AuthModelInit: AuthModel = {
  access_token: '',
  expires_in: '',
  refresh_token: '',
  token_type: '',
}

export interface RegisterData {
  firstName?: string
  lastName?: string
  username: string
  email: string
  password: string
  phone?: string
}

export const RegisterDataInit: RegisterData = {
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  password: '',
  phone: undefined,
}

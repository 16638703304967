import { configureStore } from '@reduxjs/toolkit'
import TemplateReducer from './TemplateReducer'
import SBRMReducer from './SBRMReducer'
import ControlReducer from './ControlReducer'

export const store = configureStore({
  reducer: {
    SBRM: SBRMReducer,
    template: TemplateReducer,
    control: ControlReducer,
  },
  devTools: process.env.REACT_APP_ENVIRONMENT !== 'production',
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

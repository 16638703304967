import { Button, Space, Typography } from 'antd'
import { useState } from 'react'
import i18n from '../../i18n/setupLocalization'
import { useAuth } from '../../contexts/Auth'

const { Title } = Typography

export function ResetPassword() {
  const { login } = useAuth()
  const [anchorEl, setAnchorEl] = useState(null)
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const open = Boolean(anchorEl)

  const handleLogin = () => {
    setAnchorEl(null)

    setIsLoading(true)
    login(email, password)
      .catch((err) => {
        //TODO: Handle
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Space
      direction="vertical"
      style={{ padding: 20, textAlign: 'center' }}
      size={20}
    >
      <img src="assets/linear_logo.png" width={300} />
      <Title style={{ margin: 0, marginBottom: '1rem' }}>
        {i18n.t('LOGIN.WELCOME_MESSAGE_TITLE')}
      </Title>
      <Button
        style={{ background: '#293348', color: '#fff' }}
        size="large"
        loading={isLoading}
        onClick={handleLogin}
      >
        {i18n.t('LOGIN.BUTTON_TEXT')}
      </Button>
    </Space>
  )
}

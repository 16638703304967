import { useEffect } from 'react'
import { getLoggedUserOrganizations } from '../../helpers/AuthHelper'
import { useNavigate } from 'react-router-dom'
import { Spin } from 'antd'

export default function HomePage() {
  const navigate = useNavigate()
  useEffect(() => {
    getLoggedUserOrganizations().then((result) => {
      const orgSlug = result.data.data[0].slug
      console.log('navigate to ' + `/${orgSlug}/signature`)
      navigate(`/${orgSlug}/signature`)
    })
  }, [])

  return <Spin size="large" />
}

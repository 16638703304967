import { useEffect } from 'react'
import { getLoggedUserOrganizations } from '../../helpers/AuthHelper'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../contexts/Auth'

export default function EnsureAccountIsValid() {
  const navigate = useNavigate()
  const { account } = useParams<{ account: string }>()
  const { setOrganizations } = useAuth()

  useEffect(() => {
    getLoggedUserOrganizations().then((result) => {
      const orgs = result.data.data
      setOrganizations(orgs)
      if (!orgs.map((o) => o.slug).includes(account ?? '')) {
        navigate('/')
      }
    })
  }, [])

  return <Outlet />
}

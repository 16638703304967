import axios from 'axios'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { EMAIL_VERIFY_URL } from '../../../helpers/urls'
import qs from 'qs'
import { Alert, Button, Space, Spin } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'

export default function VerifyEmailPage() {
  const navigate = useNavigate()
  const { id, hash } = useParams<{ id: string; hash: string }>()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)

    const expires = searchParams.get('expires')
    const signature = searchParams.get('signature')

    if (!hash || !id || !expires || !signature) {
      navigate('/')
      return
    }

    axios
      .get(
        `${EMAIL_VERIFY_URL}/${id}/${hash}` +
          '?' +
          qs.stringify({
            expires: expires,
            signature: signature,
          })
      )
      .then(() => {
        setIsSuccess(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [searchParams])

  return (
    <Space>
      {isLoading === true && <Spin size="large" />}
      {isSuccess === true && (
        <Alert
          type="success"
          message={'Email verified'}
          description={
            <Button
              icon={<ArrowLeftOutlined />}
              onClick={() => {
                navigate('/')
              }}
            >
              Back to home
            </Button>
          }
        />
      )}
    </Space>
  )
}

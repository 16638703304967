import { Empty, Table, TableProps } from 'antd'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { MetaData } from '../../modules/sbrm/models/MetaData'
import { useAppSelector } from '../../store/hooks'
import i18n from '../../i18n/setupLocalization'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { Actions } from '../../modules/sbrm/components/Actions'

const SBTable = <RecordType extends object = any>(
  props: React.PropsWithChildren<
    TableProps<RecordType> & {
      entity: SBRMType
      emptyActionsMetadata?: MetaData[]
      hideCreateButton?: boolean
    }
  >
) => {
  const { isOpen: nestedIsOpen } = useAppSelector((state) => state.SBRM.nested)

  return (
    <Table
      {...props}
      locale={{
        ...props.locale,
        emptyText: !props.loading && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={i18n.t('LocalizationKeys.Components.SBTable.Empty', {
              entity: i18n.t(`Entity-${props.entity}`),
            })}
          >
            {/* Testing hideCreateButton like that ensure to hide the button only when the parameter is specified */}
            {!nestedIsOpen && !(props.hideCreateButton === true) && (
              <Actions
                actions={['create']}
                entity={props.entity}
                metadata={props.emptyActionsMetadata}
              />
            )}
          </Empty>
        ),
      }}
    />
  )
}

export default SBTable

import { SBRMAction, SBRMType } from '../modules/sbrm/SBRMModel'

export enum Permission {
  // Artist
  listArtist = 'list artist',
  createArtist = 'create artist',
  viewArtist = 'view artist',
  editArtist = 'edit artist',
  deleteArtist = 'delete artist',
}

export function enumFromStringValue<T>(
  enm: { [s: string]: T },
  value: string
): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value)
    ? (value as unknown as T)
    : undefined
}

export function permissionFromEntityAndAction(
  entity: SBRMType,
  action: SBRMAction
): Permission {
  // Default way to compute AlelaPermission
  const verb = (() => {
    switch (action) {
      case 'update':
        return 'edit'
      case 'duplicate':
        return 'create'
      default:
        return action
    }
  })()

  return enumFromStringValue(Permission, verb + ' ' + entity)!
}

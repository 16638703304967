import { Alert, Button, Space, Typography } from 'antd'
import { useAuth } from '../../contexts/Auth'
import { MailOutlined, RedEnvelopeOutlined } from '@ant-design/icons'
import axios from 'axios'
import { EMAIL_RESEND_URL } from '../../helpers/urls'

const { Text } = Typography

interface Props {}

const EmailVerified = ({}: Props) => {
  const { currentUser } = useAuth()
  if (!currentUser || currentUser.emailVerifiedAt) return <></>

  return (
    <Alert
      style={{ marginTop: 15 }}
      type="warning"
      message={'Verify your email'}
      description={
        <Space
          direction="horizontal"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Text>
            You have received an email with a link to verify your email address.
            <br />
            You won&apos;t be able to download your signature as long as your
            email is not verified.
          </Text>
          <Button
            title="Resend email"
            type="primary"
            icon={<MailOutlined />}
            onClick={() => {
              axios.get(EMAIL_RESEND_URL)
            }}
          >
            Resend email
          </Button>
        </Space>
      }
    />
  )
}

export default EmailVerified

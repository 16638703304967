import { I18n } from 'i18n-js'
import English from './en'
import French from './fr'

const i18n = new I18n({
  en: English,
  fr: French,
})

i18n.locale = navigator.language.slice(0, 2)
i18n.enableFallback = true
i18n.defaultLocale = 'en'

export default i18n

import { AuthModel } from '../models/AuthModel'
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from './LocalStorageHelper'

const AUTH_LOCAL_STORAGE_TOKEN_KEY = 'my-easy-sign-auth-v1'

const getAuth = () =>
  getLocalStorageItem<AuthModel>(AUTH_LOCAL_STORAGE_TOKEN_KEY)

const setAuth = (auth: AuthModel) =>
  setLocalStorageItem<AuthModel>(AUTH_LOCAL_STORAGE_TOKEN_KEY, auth)

const removeAuth = () => removeLocalStorageItem(AUTH_LOCAL_STORAGE_TOKEN_KEY)

export { getAuth, setAuth, removeAuth }

import axios from 'axios'
import { AppDispatch } from '../store/store'
import { AuthModel, RegisterData } from '../models/AuthModel'
import {
  GET_LOGGED_USER_INFOS,
  LOGIN_URL,
  LOGOUT_URL,
  REGISTER_URL,
  FORGOT_PASSWORD_URL,
  RESET_PASSWORD_URL,
  GET_LOGGED_USER_ORGANIZATIONS,
} from './urls'
import { APIResponse } from '../models/helper'
import { User } from '../models/User'
import { Organization } from '../models/Organization'

/**
 * Reset Redux store to its initial state
 * Must be dispatched!
 */
const resetStore = () => (dispatch: AppDispatch) => {
  //   dispatch(resetAddressState())
}

function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, null, {
    headers: { Authorization: 'Basic ' + btoa(`${email}:${password}`) },
  })
}

export function register(data: RegisterData) {
  return axios.post<AuthModel>(REGISTER_URL, data)
}

export function logout() {
  return axios.post(LOGOUT_URL)
}

export function getLoggedUserInfos() {
  return axios.get<APIResponse<User>>(GET_LOGGED_USER_INFOS)
}

export function getLoggedUserOrganizations() {
  return axios.get<APIResponse<Organization[]>>(GET_LOGGED_USER_ORGANIZATIONS)
}

export function updateLoggedUserInfos(user: User) {
  return axios.put(GET_LOGGED_USER_INFOS, user)
}

export function forgotPassword(email: string) {
  return axios.post(FORGOT_PASSWORD_URL, { email: email })
}

export function resetPassword(email: string, token: string, password: string) {
  return axios.post(RESET_PASSWORD_URL, {
    email: email,
    token: token,
    password: password,
    password_confirmation: password,
  })
}

export { resetStore, login }

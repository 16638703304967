import { Avatar, Dropdown, MenuProps, Typography } from 'antd'
import { useAuth } from '../contexts/Auth'
import { useEffect } from 'react'
import { getLoggedUserInfos } from '../helpers/AuthHelper'
import { useNavigate } from 'react-router-dom'

const Text = Typography

const RightHeader = () => {
  const navigate = useNavigate()
  const { currentUser: user, setCurrentUser, logout } = useAuth()

  const items: MenuProps['items'] = [
    {
      key: 1,
      label: 'Profile',
      onClick: () => navigate('/settings/profile'),
    },
    {
      key: 2,
      label: <Text>{'Logout'}</Text>,
      onClick: logout,
    },
  ]

  useEffect(() => {
    getLoggedUserInfos().then((result) => {
      console.log(result.data.data)
      setCurrentUser(result.data.data)
    })
  }, [])

  return (
    <Dropdown menu={{ items }} placement="bottomLeft" arrow>
      <Avatar
        style={{
          color: '#fff',
          backgroundColor: '#6c7cff',
          cursor: 'pointer',
        }}
      >
        {!user && '--'}
        {user && user.firstName && user.lastName
          ? user!.firstName.slice(0, 1).toLocaleUpperCase() +
            user!.lastName.slice(0, 1).toLocaleUpperCase()
          : user?.username.slice(0, 1).toLocaleUpperCase()}
      </Avatar>
    </Dropdown>
  )
}

export default RightHeader

import LocalizationKeys from './LocalizationKeys'

const English = {
  [LocalizationKeys.Page.Login.Title]: 'Login',
  [LocalizationKeys.Page.Login.Submit]: 'Login',

  [LocalizationKeys.Page.Register.Title]: 'Register',
  [LocalizationKeys.Page.Register.Submit]: 'Register',

  [LocalizationKeys.Page.ForgotPassword.Title]: 'Forgot password',
  [LocalizationKeys.Page.ForgotPassword.Submit]: 'Submit',

  [LocalizationKeys.Page.ResetPassord.Title]: 'Reset password',
  [LocalizationKeys.Page.ResetPassord.Submit]: 'Submit',

  [LocalizationKeys.Auth.Error.LoginError]:
    'Ensure your credentials are correct. Try again later if the error persists.',

  [LocalizationKeys.Misc.Error.Required]: 'This field is required',
  [LocalizationKeys.Misc.Error.TypeString]: 'Input is not a valid string',
  [LocalizationKeys.Misc.Error.TypeNumber]: 'Input is not a valid number',
  [LocalizationKeys.Misc.Error.TypeLink]: 'Input is not a valid link',
  [LocalizationKeys.Misc.Error.TypeEmail]: 'Input is not a valid email',
  [LocalizationKeys.Misc.Error.NotValidPhone]: 'Phone number must be valid',
  [LocalizationKeys.Misc.Error.MinLength]:
    'The value must have at least %{length} caracters',
  [LocalizationKeys.Misc.Error.Caps]:
    'The value must contain at least one capital letter',
  [LocalizationKeys.Misc.Error.Lowercase]:
    'The value must contain at least one lowercase letter',
  [LocalizationKeys.Misc.Error.ContainsNumber]:
    'The value must have at lest one number',
  [LocalizationKeys.Misc.Error.ContainsSymbol]:
    'The value must have at least one symbol',
  [LocalizationKeys.Misc.Error.MismatchValue]:
    'The value mismatch the expected value',

  [LocalizationKeys.Misc.Form.Title]: 'Title',
  [LocalizationKeys.Misc.Form.Description]: 'Description',
  [LocalizationKeys.Misc.Form.Name]: 'Name',
  [LocalizationKeys.Misc.Form.Slug]: 'Slug',
  [LocalizationKeys.Misc.Form.FirstName]: 'First name',
  [LocalizationKeys.Misc.Form.LastName]: 'Last name',
  [LocalizationKeys.Misc.Form.Username]: "Nom d'utilisteur",
  [LocalizationKeys.Misc.Form.Email]: 'Email',
  [LocalizationKeys.Misc.Form.Phone]: 'Phone',
  [LocalizationKeys.Misc.Form.Password]: 'Password',
  [LocalizationKeys.Misc.Form.PasswordConfirmation]: 'Password confirmation',
  [LocalizationKeys.Misc.Form.RememberMe]: 'Remember me',
  [LocalizationKeys.Misc.Form.Add]: 'Add',

  [LocalizationKeys.SBRM.EntityNotDefined]:
    'Choose what to do first and come back later',
  [LocalizationKeys.SBRM.Close]: 'Close',
  [LocalizationKeys.SBRM.Delete]: 'Delete',
  [LocalizationKeys.SBRM.Save]: 'Save',
  [LocalizationKeys.SBRM.Update]: 'Update',
  [LocalizationKeys.SBRM.AddNew]: 'Add new',
  [LocalizationKeys.SBRM.Duplicate]: 'Duplicate',
  [LocalizationKeys.SBRM.View]: 'View',
  [LocalizationKeys.SBRM.Comment]: 'Comment',

  [LocalizationKeys.SBRMTitle.AddNew]: 'Add new %{type}',
  [LocalizationKeys.SBRMTitle.CreateNew]: 'Create new %{type}',
  [LocalizationKeys.SBRMTitle.Edit]: 'Edit %{type}',
  [LocalizationKeys.SBRMTitle.Delete]: 'Delete %{type}',
  [LocalizationKeys.SBRMTitle.Comment]: 'Comment %{type}',
  [LocalizationKeys.SBRMTitle.View]: 'View %{type}',
  [LocalizationKeys.SBRMTitle.Upload]: 'Upload %{type}',
  [LocalizationKeys.SBRMTitle.Duplicate]: 'Duplicate %{type}',
  [LocalizationKeys.SBRMTitle.Assign]: 'Assign %{type}',
  [LocalizationKeys.SBRMTitle.Invite]: 'Invite %{type}',
  [LocalizationKeys.SBRMTitle.Cancel]: 'Cancel %{type}',

  [LocalizationKeys.Entity.template]: 'template',
  [LocalizationKeys.Entity.control]: 'control',
}

export default English

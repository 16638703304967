import { TableParams } from './TableParams'

export const initialQuery: TableParams = {
  pagination: { current: 1, pageSize: 20 },
}

export interface SBModel {
  id: number
}

interface APIResponse<T> {
  status: number
  data: T
}

interface MessageResponse {
  status: number
  message: string
}

export { type APIResponse, type MessageResponse }

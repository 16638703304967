import { useEffect, useState } from 'react'
import { Form, FormInstance, Input, Radio } from 'antd'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { useAppSelector } from '../../../store/hooks'
import i18n from '../../../i18n/setupLocalization'
import { requiredRule, stringRule } from '../../../helpers/rules'

interface Props {
  form: FormInstance
}

const Create = ({ form }: Props) => {
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  useEffect(() => {
    if (!SBRMIsOpen) {
      // We want to trigger only the reset
      // when the SBRM opens
      return
    }
    //
  }, [SBRMIsOpen])

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="name"
        label={i18n.t(LocalizationKeys.Misc.Form.Name)}
        rules={[requiredRule(), stringRule()]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="slug"
        label={i18n.t(LocalizationKeys.Misc.Form.Slug)}
        rules={[requiredRule(), stringRule()]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="type"
        label={'Type'}
        rules={[requiredRule(), stringRule()]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="placeholder"
        label={'Placeholder'}
        rules={[requiredRule(), stringRule()]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="defaultCode"
        label={'Default code'}
        rules={[requiredRule(), stringRule()]}
      >
        <Input />
      </Form.Item>
    </Form>
  )
}

export default Create

const API_URL = process.env.REACT_APP_API_URL

export const CSRF_COOKE_URL = `${API_URL}/sanctum/csrf-cookie`

export const LOGIN_URL = `${API_URL}/auth/login`
export const LOGOUT_URL = `${API_URL}/auth/logout`
export const REGISTER_URL = `${API_URL}/auth/register`
export const GET_LOGGED_USER_INFOS = `${API_URL}/auth/me`
export const GET_LOGGED_USER_ORGANIZATIONS = `${API_URL}/auth/me/organizations`
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`
export const FORGOT_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const EMAIL_RESEND_URL = `${API_URL}/auth/email/resend`
export const EMAIL_VERIFY_URL = `${API_URL}/auth/email/verify`

export const TEMPLATE_URL = `${API_URL}/templates`
export const CONTROL_URL = `${API_URL}/controls`

import './App.css'
import { App as ANTDApp, ConfigProvider } from 'antd'
import { Outlet } from 'react-router-dom'
import { useLang } from './hooks/useLang'
import frFR from 'antd/locale/fr_FR'
import { AuthProvider } from './contexts/Auth'
import { setupAxios } from './helpers/setupAxios'
import axios from 'axios'
import SBRM from './modules/sbrm/SBRM'

function App() {
  const { selectedLocale } = useLang()

  setupAxios(axios)

  return (
    <ConfigProvider
      locale={selectedLocale === 'fr' ? frFR : undefined}
      theme={{ token: { colorPrimary: '#6c7cff' } }}
    >
      <ANTDApp>
        <AuthProvider>
          <Outlet />
          <SBRM />
        </AuthProvider>
      </ANTDApp>
    </ConfigProvider>
  )
}

export default App

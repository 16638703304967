import { AxiosStatic } from 'axios'
import { useLang } from '../hooks/useLang'
import { useParams } from 'react-router-dom'

export function setupAxios(axios: AxiosStatic) {
  const { selectedLocale } = useLang()
  const { account } = useParams<{ account: string }>()

  // Requests default headers
  axios.defaults.withXSRFToken = true
  axios.defaults.withCredentials = true
  axios.defaults.headers.common['Accept'] = 'application/json'
  axios.defaults.headers.common['Accept-Language'] = selectedLocale

  // Request interceptor for API calls
  axios.interceptors.request.use(
    async (config) => {
      if (account) {
        config.params = config.params || {}
        config.params.account = account
      }

      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )

  // Response interceptor for API calls
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    async function (error) {
      const originalRequest = error.config
      const response = error.response
      if (!response) return
      if (error.response.status === 401 && !originalRequest._retry) {
        /**
         * The API returned a 401 - Unauthorized status code
         */
        console.log('401')
        window.location.href = '/auth/login'

        return Promise.reject(error)
      }

      /**
       * It is not a special error, just return the error
       */
      return Promise.reject(error)
    }
  )
}

import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Space,
  Typography,
} from 'antd'
import { useState } from 'react'
import i18n from '../../i18n/setupLocalization'
import { useAuth } from '../../contexts/Auth'
import axios from 'axios'
import { CSRF_COOKE_URL } from '../../helpers/urls'
import { emailRule, requiredRule } from '../../helpers/rules'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import { Link } from 'react-router-dom'

const { Title } = Typography

export function Login() {
  const { login } = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const handleLogin = (values: { email: string; password: string }) => {
    setIsLoading(true)
    setError(undefined)

    axios
      .get(CSRF_COOKE_URL)
      .then(() => {
        login(values.email, values.password)
          .catch((err) => {
            setError(i18n.t(LocalizationKeys.Auth.Error.LoginError))
          })
          .finally(() => {
            setIsLoading(false)
          })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Space
      direction="vertical"
      style={{ padding: 20, textAlign: 'center' }}
      size={20}
    >
      <img src="/assets/logo_mes.png" width={300} />
      <Title style={{ margin: 0, marginBottom: '1rem' }}>
        {i18n.t(LocalizationKeys.Page.Login.Title)}
      </Title>
      <div style={{ textAlign: 'center', marginTop: 10 }}>
        {error && (
          <Alert style={{ marginBottom: 25 }} message={error} type="error" />
        )}
      </div>
      <Form onFinish={handleLogin}>
        <Form.Item name="email" rules={[requiredRule(), emailRule()]}>
          <Input
            placeholder={i18n.t(LocalizationKeys.Misc.Form.Email)}
            autoComplete="email"
          />
        </Form.Item>
        <Form.Item name="password" rules={[requiredRule()]}>
          <Input.Password
            placeholder={i18n.t(LocalizationKeys.Misc.Form.Password)}
            autoComplete="current-password"
          />
        </Form.Item>
        <Form.Item name="remember" valuePropName="checked">
          <Checkbox>{i18n.t(LocalizationKeys.Misc.Form.RememberMe)}</Checkbox>
        </Form.Item>
        <Form.Item style={{ marginBottom: 15 }}>
          <Button
            className="w-full"
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            {i18n.t(LocalizationKeys.Page.Login.Submit)}
          </Button>
        </Form.Item>
      </Form>
      <Divider />
      <Link to={'/auth/forgot-password'}>
        {i18n.t(LocalizationKeys.Page.ForgotPassword.Title)}
      </Link>
      <Link to={'/auth/register'}>No account ? Register</Link>
    </Space>
  )
}

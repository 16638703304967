import { Rule } from 'antd/es/form'
import LocalizationKeys from '../i18n/LocalizationKeys'
import i18n from '../i18n/setupLocalization'

export const integerRule: Rule = {
  type: 'integer',
  message: 'The input is not valid number',
}

export const minLengthRule = function (length: number): Rule {
  return {
    pattern: new RegExp(`.{${length.toString()},}`, 'g'),
    message: i18n.t(LocalizationKeys.Misc.Error.MinLength, { length: length }),
  }
}

export const capsRule = function (): Rule {
  return {
    pattern: new RegExp(/[A-Z]+/g),
    message: i18n.t(LocalizationKeys.Misc.Error.Caps),
  }
}
export const lowercaseRule = function (): Rule {
  return {
    pattern: new RegExp(/[a-z]+/g),
    message: i18n.t(LocalizationKeys.Misc.Error.Lowercase),
  }
}

export const containsNumberRule = function (): Rule {
  return {
    pattern: new RegExp(/\d+/g),
    message: i18n.t(LocalizationKeys.Misc.Error.ContainsNumber),
  }
}
export const containsSymbolRule = function (): Rule {
  return {
    pattern: new RegExp(/\W+/g),
    message: i18n.t(LocalizationKeys.Misc.Error.ContainsSymbol),
  }
}

export const numberRule = function (): Rule {
  return {
    type: 'number',
    message: i18n.t(LocalizationKeys.Misc.Error.TypeNumber),
  }
}

export const stringRule = function (): Rule {
  return {
    type: 'string',
    message: i18n.t(LocalizationKeys.Misc.Error.TypeString),
  }
}

export const urlRule = function (): Rule {
  return {
    type: 'url',
    message: i18n.t(LocalizationKeys.Misc.Error.TypeLink),
  }
}

export const emailRule = function (): Rule {
  return {
    type: 'email',
    message: i18n.t(LocalizationKeys.Misc.Error.TypeEmail),
  }
}

export const requiredRule = function (): Rule {
  return {
    required: true,
    message: i18n.t(LocalizationKeys.Misc.Error.Required),
  }
}

export const acceptedRule = function (): Rule {
  return {
    validator(_, value) {
      if (value) {
        return Promise.resolve()
      }
      return Promise.reject(i18n.t(LocalizationKeys.Misc.Error.Required))
    },
  }
}

export const nestedCascaderExceptAddressRule = function (
  minDepth: number = 2
): Rule {
  return {
    validator(_, value) {
      if (value && value.length === 1 && value[0] === 'customaddress') {
        return Promise.resolve()
      }
      return value.length >= minDepth
        ? Promise.resolve()
        : Promise.reject(i18n.t(LocalizationKeys.Misc.Error.Required))
    },
  }
}

export const matchValueRule = (expectedValue: string): Rule => ({
  validator(_rule: any, value: string) {
    if (value === expectedValue) {
      return Promise.resolve()
    }
    return Promise.reject(
      new Error(i18n.t(LocalizationKeys.Misc.Error.MismatchValue))
    )
  },
})

export const notMatchValuesRule = (reserved: string[]): Rule => ({
  validator(_rule: any, value: string) {
    if (reserved.includes(value)) {
      return Promise.reject(new Error('This value is reserved'))
    }
    return Promise.resolve()
  },
})

export const lessOneIsChecked = (array: any[]): Rule => ({
  validator(_rule: any, value: string) {
    if (array.length > 0) {
      return Promise.resolve()
    }
    return Promise.reject(
      new Error(i18n.t(LocalizationKeys.Misc.Error.MismatchValue))
    )
  },
})

import { Badge, Space, Typography } from 'antd'
import React from 'react'

const { Title } = Typography

interface PageTitleProps extends React.HTMLProps<HTMLElement> {
  title: string
  level?: 1 | 2 | 3 | 4 | 5 | undefined
  toolbar?: React.ReactNode
  badge?: number
}

const PageTitle: React.FC<PageTitleProps> = ({
  title,
  level = 3,
  toolbar,
  badge,
  id,
  style,
}) => {
  return (
    <Space
      id={id ?? undefined}
      style={{
        justifyContent: 'space-between',
        marginTop: 20,
        display: 'flex',
        marginBottom: 20,
        ...style,
      }}
      wrap={true}
    >
      <Space direction="horizontal">
        <Title level={level} style={{ margin: 0, wordBreak: 'normal' }}>
          {title}
        </Title>
        <Badge count={badge}></Badge>
      </Space>
      <Space direction="horizontal">{toolbar && toolbar}</Space>
    </Space>
  )
}

export default PageTitle

import {
  FC,
  useState,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
  PropsWithChildren,
} from 'react'
import { User } from '../models/User'
import { useNavigate } from 'react-router-dom'
import { AuthModel, AuthModelInit, RegisterData } from '../models/AuthModel'
import * as authHelper from '../helpers/AuthLocalStorageHelper'
import {
  resetStore,
  logout as logoutAPI,
  updateLoggedUserInfos,
  getLoggedUserInfos,
  login as loginAPI,
  register as registerAPI,
} from '../helpers/AuthHelper'
import { useAppDispatch } from '../store/hooks'
import { Organization } from '../models/Organization'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: User | undefined
  setCurrentUser: Dispatch<SetStateAction<User | undefined>>
  isLogged: boolean | undefined
  setIsLogged: Dispatch<SetStateAction<boolean | undefined>>
  organizations: Organization[]
  setOrganizations: Dispatch<SetStateAction<Organization[]>>
  register: (data: RegisterData) => Promise<AuthModel>
  login: (email: string, password: string) => Promise<void>
  logout: () => void
  updateUser: (user: User) => Promise<boolean>
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  isLogged: undefined,
  setIsLogged: () => {},
  organizations: [],
  setOrganizations: () => {},
  register: () => Promise.resolve(AuthModelInit),
  login: () => Promise.resolve(),
  logout: () => {},
  updateUser: ({}) => new Promise<boolean>(() => false),
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [currentUser, setCurrentUser] = useState<User | undefined>()
  const [organizations, setOrganizations] = useState<Organization[]>([])
  const [isLogged, setIsLogged] = useState<boolean | undefined>(undefined)
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const register = (data: RegisterData) =>
    registerAPI(data).then((result) => result.data)

  const login = async (email: string, password: string) => {
    try {
      const { data: auth } = await loginAPI(email, password)
      saveAuth(auth)
      const { data: userResponse } = await getLoggedUserInfos()
      setIsLogged(true)
      setCurrentUser(userResponse.data)
      const queryParameters = new URLSearchParams(window.location.search)
      const returnTo = queryParameters.get('returnTo')
      navigate(returnTo ? returnTo : '/')
    } catch (error) {
      saveAuth(undefined)
      throw error
    }
  }

  const logout = () => {
    logoutAPI().finally(() => {
      setIsLogged(false)
      setCurrentUser(undefined)
      saveAuth(undefined)
      dispatch(resetStore())
      navigate('/auth/login')
    })
  }

  const updateUser = (user: User) =>
    updateLoggedUserInfos(user).then((_) => {
      getLoggedUserInfos().then((data) => setCurrentUser(data.data.data))
      return true
    })

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        isLogged,
        setIsLogged,
        organizations,
        setOrganizations,
        register,
        login,
        logout,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, useAuth }

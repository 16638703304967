import { SBRMType } from './SBRMModel'
import { SBRMAction } from './SBRMModel'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import i18n from '../../i18n/setupLocalization'

enum SBRMTitleAction {
  AddNew = 'AddNew',
  CreateNew = 'CreateNew',
  Edit = 'Edit',
  Delete = 'Delete',
  Comment = 'Comment',
  View = 'View',
  Upload = 'Upload',
  Duplicate = 'Duplicate',
  Assign = 'Assign',
  Invite = 'Invite',
  Cancel = 'Cancel',
}

const SBRMTitle = (
  entity: SBRMType | undefined,
  action: SBRMAction | undefined
): string => {
  if (entity == undefined || action == undefined) return 'Error'

  const localizeType = (type: SBRMType): string =>
    i18n.t(LocalizationKeys.Entity[type])

  const composeTitle = (action: SBRMTitleAction, type: SBRMType): string =>
    i18n.t(LocalizationKeys.SBRMTitle[action], {
      type: localizeType(type),
    })

  const titles: Record<SBRMType, Partial<Record<SBRMAction, string>>> = {
    [SBRMType.template]: {
      ['create']: composeTitle(SBRMTitleAction.CreateNew, SBRMType.template),
      ['update']: composeTitle(SBRMTitleAction.Edit, SBRMType.template),
      ['delete']: composeTitle(SBRMTitleAction.Delete, SBRMType.template),
    },
    [SBRMType.control]: {
      ['create']: composeTitle(SBRMTitleAction.CreateNew, SBRMType.control),
      ['update']: composeTitle(SBRMTitleAction.Edit, SBRMType.control),
      ['delete']: composeTitle(SBRMTitleAction.Delete, SBRMType.control),
    },
  }

  const title = titles[entity][action]
  return title !== undefined ? title : ''
}

export default SBRMTitle

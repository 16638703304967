import { useRef } from 'react'
import { SBRMFormInterface, SBRMType } from './SBRMModel'
import { SBRMState } from '../../store/SBRMReducer'
import { useAppSelector } from '../../store/hooks'

// Form imports
import { Template } from '../../components/template/Template'
import { Control } from '../../components/control/Control'

type useSBRMformsProps = {
  isNested: boolean
}

const useSBRMforms = ({
  isNested,
}: useSBRMformsProps): {
  refs: Record<SBRMType, React.RefObject<SBRMFormInterface>>
  forms: Record<SBRMType, JSX.Element>
} => {
  const SBRMrefs = {
    [SBRMType.template]: useRef<SBRMFormInterface>(null),
    [SBRMType.control]: useRef<SBRMFormInterface>(null),
  }

  const SBRMforms = {
    [SBRMType.template]: (
      <Template.Form ref={SBRMrefs[SBRMType.template]} isNested={isNested} />
    ),
    [SBRMType.control]: (
      <Control.Form ref={SBRMrefs[SBRMType.control]} isNested={isNested} />
    ),
  }
  return { refs: SBRMrefs, forms: SBRMforms }
}

const useSBRMData = (isNested: boolean): SBRMState => {
  const {
    isOpen: SBRMisOpen,
    entity: SBRMentity,
    action: SBRMaction,
    metadata: SBRMmetadata,
  } = useAppSelector((state) => state.SBRM)
  const {
    isOpen: SBRMnestedIsOpen,
    entity: SBRMnestedEntity,
    action: SBRMnestedAction,
    metadata: SBRMnestedMetadata,
  } = useAppSelector((state) => state.SBRM.nested)

  return {
    isOpen: isNested ? SBRMnestedIsOpen : SBRMisOpen,
    entity: isNested ? SBRMnestedEntity : SBRMentity,
    action: isNested ? SBRMnestedAction : SBRMaction,
    metadata: SBRMmetadata,
  }
}

export { useSBRMforms, useSBRMData }

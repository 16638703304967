import React, { useEffect, useState } from 'react'
import { Divider, Layout, Menu, theme } from 'antd'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  DatabaseOutlined,
  HomeOutlined,
  SignatureOutlined,
  SolutionOutlined,
} from '@ant-design/icons'
import i18n from '../i18n/setupLocalization'
import LocalizationKeys from '../i18n/LocalizationKeys'
import { Organization } from '../components/organization/Organization'
import { extractSectionFromPath } from '../helpers/RouterHelpers'

const { Sider } = Layout

export const siderWidth = 300
export const siderCollapsedWidth = 80

interface Props {}

export const LayoutSider = ({}: Props) => {
  const navigate = useNavigate()
  const { token } = theme.useToken()
  const { pathname } = useLocation()
  const { account } = useParams<{ account: string }>()
  const [selectedKey, setSelectedKey] = useState<
    'signature' | 'templates' | 'controls'
  >('signature')

  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    backgroundColor: '#fff',
    paddingTop: 10,
  }

  useEffect(() => {
    const menuItemSelected = extractSectionFromPath(pathname, 2)
    setSelectedKey(menuItemSelected as any)
  }, [pathname])

  return (
    <Sider collapsed={false} style={contentStyle}>
      <Organization.Selector />
      <Divider />
      <Menu
        style={contentStyle}
        selectedKeys={[selectedKey]}
        mode="inline"
        items={[
          {
            key: 'signature',
            icon: <SignatureOutlined />,
            label: 'Signature',
            onClick: () => navigate(`/${account}/signature`),
          },
          {
            key: 'templates',
            icon: <DatabaseOutlined />,
            label: 'Templates',
            onClick: () => navigate(`/${account}/templates`),
          },
          {
            key: 'controls',
            icon: <DatabaseOutlined />,
            label: 'Controls',
            onClick: () => navigate(`/${account}/controls`),
          },
        ]}
      />
    </Sider>
  )
}

import {
  SBState,
  SBSelectRaw,
  getIdOrModelId,
  baseReducers,
} from '../helpers/ReducerHelper'
import {
  SBAPICreate,
  SBAPIDelete,
  SBAPIFetchPaginatedDispatch,
  SBAPIUpdate,
} from '../helpers/SBAPIHelper'
import { Control } from '../models/Template'
import { AppDispatch, RootState } from './store'
import { TableParams } from '../models/TableParams'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { CONTROL_URL } from '../helpers/urls'
import { controlSchema } from '../models/schema'

const initialState: SBState<Control> = {
  isLoading: false,
  error: null,
  items: {},
  ids: [],
  selectedId: undefined,
  query: {
    pagination: {
      current: 1,
      pageSize: 10,
    },
  },
}

const slice = createSlice({
  name: 'control',
  initialState,
  reducers: baseReducers,
})

// Reducer
export default slice.reducer
export const {
  getItemsSuccess: getControlsSuccess,
  setQuery: setControlQuery,
  reset: resetControlState,
  resetQueryAndIds: resetControlQueryAndIds,
} = slice.actions

/**
 * Selectors
 */

const selectRawItems: SBSelectRaw<{ [key: string]: Control }> = (
  state: RootState
) => state[slice.name].items
const selectRawIds: SBSelectRaw<number[]> = (state: RootState) =>
  state[slice.name].ids
const selectRawSelectedId: SBSelectRaw<number | undefined> = (
  state: RootState
) => state[slice.name].selectedId

export const selectControls = () =>
  createSelector(
    [selectRawItems, selectRawIds],
    (items, ids) => ids.map((id) => items[id]).filter((i) => i)
    // Filter allow to return only non-null elements
  )
export const selectSelectedControl = () =>
  createSelector([selectRawItems, selectRawSelectedId], (items, id) =>
    id !== undefined ? items[id] : undefined
  )
export const selectControlById = (id: number) =>
  createSelector([selectRawItems], (items) =>
    items.hasOwnProperty(id) ? items[id] : undefined
  )
export const selectControlsByIds = (ids: number[]) =>
  createSelector([selectRawItems], (items) =>
    ids.filter((id) => items.hasOwnProperty(id)).map((id) => items[id])
  )

/**
 * Actions
 */

export const setSelectedControl =
  (control: Control | number) => async (dispatch: AppDispatch) =>
    dispatch(slice.actions.setSelectedId(getIdOrModelId<Control>(control)))

export const getControls = (params: TableParams) =>
  SBAPIFetchPaginatedDispatch<Control>(
    CONTROL_URL,
    params,
    [controlSchema],
    slice.actions
  )

export const createControl = (control: Control) =>
  SBAPICreate<Control>(control, CONTROL_URL, controlSchema, slice.actions)

export const updateControl = (control: Control) =>
  SBAPIUpdate<Control>(control, `${CONTROL_URL}/${control.id}`, slice.actions)

export const deleteControl = (control: Control) =>
  SBAPIDelete<Control>(control, `${CONTROL_URL}/${control.id}`, slice.actions)

import { Form, FormInstance, Input } from 'antd'
import { useEffect } from 'react'
import LocalizationKeys from '../../../i18n/LocalizationKeys'
import { selectSelectedTemplate } from '../../../store/TemplateReducer'
import { useAppSelector } from '../../../store/hooks'
import { requiredRule, stringRule } from '../../../helpers/rules'
import i18n from '../../../i18n/setupLocalization'

interface Props {
  form: FormInstance
}

const Update = ({ form }: Props) => {
  const item = useAppSelector(selectSelectedTemplate())

  useEffect(() => {
    form.setFieldsValue(item)
  }, [item])

  if (!item) {
    return <></>
  }

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="id"
        required
        rules={[requiredRule()]}
        style={{ display: 'none' }}
      >
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="name"
        required
        label={i18n.t(LocalizationKeys.Misc.Form.Name)}
        rules={[requiredRule(), stringRule()]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="slug"
        required
        label={i18n.t(LocalizationKeys.Misc.Form.Name)}
        rules={[requiredRule(), stringRule()]}
      >
        <Input />
      </Form.Item>
    </Form>
  )
}

export default Update

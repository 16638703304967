import { MetaData } from './models/MetaData'
import { SetURLSearchParams } from 'react-router-dom'
import { SBRMAction, SBRMType } from './SBRMModel'

const addUrlParams = (
  params: { action: SBRMAction; entity: SBRMType; entityId?: string },
  metadata: MetaData[] | undefined,
  setSearchParams: SetURLSearchParams
): void => {
  //Grab current url params
  const currentUrlParams = new URLSearchParams(window.location.search)

  // Add parameters
  for (const [key, value] of Object.entries(params)) {
    currentUrlParams.set(key, value)
  }

  if (metadata !== undefined && metadata.length > 0) {
    currentUrlParams.set('metadata', JSON.stringify(metadata))
  }

  setSearchParams(currentUrlParams)
}

const removeUrlParams = (
  setSearchParams: SetURLSearchParams,
  keys: string[]
): void => {
  const currentUrlParams = new URLSearchParams(window.location.search)
  for (const key of keys) {
    currentUrlParams.delete(key)
  }

  setSearchParams(currentUrlParams)
}

export { addUrlParams, removeUrlParams }

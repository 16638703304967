import { FC, useLayoutEffect } from 'react'
import App from '../App'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import LayoutGeneral from '../layout/LayoutGeneral'
import { AuthLayout } from '../layout/auth/AuthLayout'
import { PublicLayout } from '../layout/PublicLayout'
import LegalNotice from '../pages/legal/LegalNotice'
import DataPrivacy from '../pages/legal/DataPrivacy'
import CGA from '../pages/legal/CGA'
import CGV from '../pages/legal/CGV'
import { Login } from '../pages/auth/Login'
import { Register } from '../pages/auth/Register'
import { ResetPassword } from '../pages/auth/ResetPassword'
import { ForgotPassword } from '../pages/auth/ForgotPassword'
import SignaturePage from '../pages/app/SignaturePage'
import VerifyEmailPage from '../pages/app/settings/VerifyEmailPage'
import ProfilePage from '../pages/app/settings/ProfilePage'
import HomePage from '../pages/utils/HomePage'
import EnsureAccountIsValid from '../pages/utils/EnsureAccountIsValid'
import TemplatesPage from '../pages/app/TemplatesPage'
import ControlsPage from '../pages/app/ControlsPage'

const AppRoutes: FC = () => {
  const location = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <Routes>
      <Route element={<App />}>
        <Route element={<LayoutGeneral />}>
          <Route path="/" element={<HomePage />} />

          <Route path="/:account" element={<EnsureAccountIsValid />}>
            <Route path="/:account/signature" element={<SignaturePage />} />
            <Route path="/:account/templates" element={<TemplatesPage />} />
            <Route path="/:account/controls" element={<ControlsPage />} />
          </Route>

          <Route path="/settings">
            <Route path="profile" element={<ProfilePage />} />
            <Route
              path="verify-email/:id/:hash"
              element={<VerifyEmailPage />}
            />
          </Route>
        </Route>

        <Route path="/auth" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="forgot-password" element={<ForgotPassword />} />

          <Route index path="*" element={<Navigate to="/auth/login" />} />
        </Route>

        <Route element={<PublicLayout />}>
          <Route path="/cga" element={<CGA />} />
          <Route path="/cgv" element={<CGV />} />
          <Route path="/data-privacy" element={<DataPrivacy />} />
          <Route path="/legal-notice" element={<LegalNotice />} />
        </Route>

        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  )
}

export { AppRoutes }

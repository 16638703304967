import { Col, Layout, Row } from 'antd'
import { LayoutFooter } from '../LayoutFooter'
import { Outlet } from 'react-router-dom'

const { Content } = Layout

export const AuthLayout: React.FC = () => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout className="site-layout">
        <Content
          style={{
            margin: '0',
            backgroundImage:
              "url('https://images.unsplash.com/photo-1527295699943-41f460f6f4c7?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Row
            justify="center"
            style={{
              padding: 0,
              margin: 0,
              height: '100vh',
            }}
          >
            <Col
              xxl={8}
              xl={10}
              lg={12}
              md={14}
              xs={24}
              style={{
                justifyContent: 'center',
                alignSelf: 'center',
                textAlign: 'center',
                background: '#f8f8f2',
              }}
            >
              <Outlet />
            </Col>
          </Row>
        </Content>
        <LayoutFooter />
      </Layout>
    </Layout>
  )
}

enum Login {
  Title = 'Page-Login-Title',
  Submit = 'Page-Login-Submit',
}
enum Register {
  Title = 'Page-Register-Title',
  Submit = 'Page-Register-Submit',
}
enum ForgotPassword {
  Title = 'Page-ForgotPassword-Title',
  Submit = 'Page-ForgotPassword-Submit',
}
enum ResetPassord {
  Title = 'Page-ResetPassord-Title',
  Submit = 'Page-ResetPassord-Submit',
}

enum Error {
  Required = 'Misc-Error-Required',
  TypeString = 'Misc-Error-TypeString',
  TypeNumber = 'Misc-Error-TypeNumber',
  TypeLink = 'Misc-Error-TypeLink',
  TypeEmail = 'Misc-Error-TypeEmail',
  NotValidPhone = 'Misc-Error-NotValidPhone',
  MinLength = 'Misc-Error-MinLength',
  Caps = 'Misc-Error-Caps',
  Lowercase = 'Misc-Error-Lowercase',
  ContainsNumber = 'Misc-Error-ContainsNumber',
  ContainsSymbol = 'Misc-Error-ContainsSymbol',
  MismatchValue = 'Misc-Error-MismatchValue',
}

enum Form {
  Title = 'Misc-Form-Title',
  Description = 'Misc-Form-Description',
  Name = 'Misc-Form-Name',
  Slug = 'Misc-Form-Slug',
  FirstName = 'Misc-Form-FirstName',
  LastName = 'Misc-Form-LastName',
  Username = 'Misc-Form-Username',
  Email = 'Misc-Form-Email',
  Phone = 'Misc-Form-Phone',
  Password = 'Misc-Form-Password',
  PasswordConfirmation = 'Misc-Form-Password-Confirmation',
  RememberMe = 'Misc-Form-RememberMe',
  Add = 'Misc-Form-Add',
}

enum AuthError {
  LoginError = 'Auth-Error-LoginError',
}

enum SBRM {
  EntityNotDefined = 'SBRM-EntityNotDefined',
  Close = 'SBRM-Close',
  Delete = 'SBRM-Delete',
  Save = 'SBRM-Save',
  Update = 'SBRM-Update',
  ContactAssociatedWithUserNotEditable = 'SBRM-ContactAssociatedWithUserNotEditable',
  AddNew = 'SBRM-AddNew',
  Duplicate = 'SBRM-Duplicate',
  View = 'SBRM-View',
  Comment = 'SBRM-Comment',
}

enum SBRMTitle {
  AddNew = 'SBRM-Title-AddNew',
  CreateNew = 'SBRM-Title-CreateNew',
  Edit = 'SBRM-Title-Edit',
  Delete = 'SBRM-Title-Delete',
  Comment = 'SBRM-Title-Comment',
  View = 'SBRM-Title-View',
  Upload = 'SBRM-Title-Upload',
  Duplicate = 'SBRM-Title-Duplicate',
  Assign = 'SBRM-Title-Assign',
  Invite = 'SBRM-Title-Invite',
  Cancel = 'SBRM-Title-Cancel',
}

enum Entity {
  template = 'template',
  control = 'control',
}

const LocalizationKeys = {
  Page: {
    Login: Login,
    Register: Register,
    ForgotPassword: ForgotPassword,
    ResetPassord: ResetPassord,
  },
  Auth: {
    Error: AuthError,
  },
  Misc: {
    Error: Error,
    Form: Form,
  },
  SBRM: SBRM,
  SBRMTitle: SBRMTitle,
  Entity: Entity,
}

export default LocalizationKeys

import { SBModel } from '../../models/helper'
import { setSelectedControl } from '../../store/ControlReducer'
import { setSelectedTemplate } from '../../store/TemplateReducer'
import { SBRMType, SBRMTypeInfosType } from './SBRMModel'

export const SBRMTypeInfos: SBRMTypeInfosType<SBModel> = {
  [SBRMType.template]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedTemplate,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'template',
  },
  [SBRMType.control]: {
    creatable: true,
    updatable: true,
    viewable: true,
    deletable: true,
    duplicable: false,
    methods: {
      setSelected: setSelectedControl,
    },
    layout: {
      create: { container: 'drawer', content: { rightPanelContent: 'form' } },
      duplicate: {
        container: 'drawer',
        content: { rightPanelContent: 'form' },
      },
      update: { container: 'drawer', content: { rightPanelContent: 'form' } },
      view: { container: 'drawer', content: { rightPanelContent: 'form' } },
      delete: { container: 'modal', content: { mainPanelContent: 'form' } },
    },
    reducerName: 'control',
  },
}

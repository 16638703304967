import LocalizationKeys from './LocalizationKeys'

const French = {
  [LocalizationKeys.Page.Login.Title]: 'Connexion',
  [LocalizationKeys.Page.Login.Submit]: 'Connexion',

  [LocalizationKeys.Page.Register.Title]: "S'inscrire",
  [LocalizationKeys.Page.Register.Submit]: 'Inscription',

  [LocalizationKeys.Page.ForgotPassword.Title]: 'Mot de passe oublié',
  [LocalizationKeys.Page.ForgotPassword.Submit]: 'Envoyer',

  [LocalizationKeys.Page.ResetPassord.Title]: 'Mot de passe oublié',
  [LocalizationKeys.Page.ResetPassord.Submit]: 'Envoyer',

  [LocalizationKeys.Auth.Error.LoginError]:
    "Vérifiez que vos identifiants soient corrects. Réessayer plus tard si l'erreur persiste",

  [LocalizationKeys.Misc.Error.Required]: 'Le champs est requis',
  [LocalizationKeys.Misc.Error.TypeString]:
    "L'entrée n'est pas une chaîne de caractères valide",
  [LocalizationKeys.Misc.Error.TypeNumber]:
    "L'entrée n'est pas un nombre valide",
  [LocalizationKeys.Misc.Error.TypeLink]: "L'entrée n'est pas un lien valide",
  [LocalizationKeys.Misc.Error.TypeEmail]: "L'entrée n'est pas un email valide",
  [LocalizationKeys.Misc.Error.NotValidPhone]:
    "L'entrée n'est pas un numéro de téléphone valide",
  [LocalizationKeys.Misc.Error.MinLength]:
    'La valeur doit avoir au moins %{length} caractères',
  [LocalizationKeys.Misc.Error.Caps]:
    'La valeur doit contenir au moins une lettre capitale',
  [LocalizationKeys.Misc.Error.Lowercase]:
    'La valeur doit contenir au moins une lettre minuscule',
  [LocalizationKeys.Misc.Error.ContainsNumber]:
    'La valeur doit contenir au moins un chiffre',
  [LocalizationKeys.Misc.Error.ContainsSymbol]:
    'La valeur doit contenir au moins un symbol',
  [LocalizationKeys.Misc.Error.MismatchValue]:
    'La valeur ne correspond pas à la valeur attendue',

  [LocalizationKeys.Misc.Form.Title]: 'Titre',
  [LocalizationKeys.Misc.Form.Description]: 'Description',
  [LocalizationKeys.Misc.Form.Name]: 'Nom',
  [LocalizationKeys.Misc.Form.Slug]: 'Slug',
  [LocalizationKeys.Misc.Form.FirstName]: 'Prénom',
  [LocalizationKeys.Misc.Form.LastName]: 'Nom',
  [LocalizationKeys.Misc.Form.Username]: 'Username',
  [LocalizationKeys.Misc.Form.Email]: 'Email',
  [LocalizationKeys.Misc.Form.Phone]: 'Téléphone',
  [LocalizationKeys.Misc.Form.Password]: 'Mot de passe',
  [LocalizationKeys.Misc.Form.PasswordConfirmation]:
    'Confirmation du mot de passe',
  [LocalizationKeys.Misc.Form.RememberMe]: 'Se souvenir de moi',
  [LocalizationKeys.Misc.Form.Add]: 'Ajouter',

  [LocalizationKeys.SBRM.EntityNotDefined]:
    'Choisissez une action et revenez plus tard',
  [LocalizationKeys.SBRM.Close]: 'Fermer',
  [LocalizationKeys.SBRM.Delete]: 'Supprimer',
  [LocalizationKeys.SBRM.Save]: 'Sauvegarder',
  [LocalizationKeys.SBRM.Update]: 'Modifier',
  [LocalizationKeys.SBRM.AddNew]: 'Ajouter',
  [LocalizationKeys.SBRM.Duplicate]: 'Dupliquer',
  [LocalizationKeys.SBRM.View]: 'Voir',
  [LocalizationKeys.SBRM.Comment]: 'Commenter',
  [LocalizationKeys.SBRMTitle.AddNew]: 'Ajouter un nouveau {type}',
  [LocalizationKeys.SBRMTitle.CreateNew]: 'Créer un nouveau {type}',
  [LocalizationKeys.SBRMTitle.Edit]: 'Modifier {type}',
  [LocalizationKeys.SBRMTitle.Delete]: 'Supprimer {type}',
  [LocalizationKeys.SBRMTitle.Comment]: 'Commenter {type}',
  [LocalizationKeys.SBRMTitle.View]: 'Voir {type}',
  [LocalizationKeys.SBRMTitle.Upload]: 'Téléverser {type}',
  [LocalizationKeys.SBRMTitle.Duplicate]: 'Dupliquer {type}',
  [LocalizationKeys.SBRMTitle.Assign]: 'Assigner {type}',
  [LocalizationKeys.SBRMTitle.Invite]: 'Inviter {type}',
  [LocalizationKeys.SBRMTitle.Cancel]: 'Annuler {type}',

  [LocalizationKeys.Entity.template]: 'template',
  [LocalizationKeys.Entity.control]: 'contrôle',
}

export default French

import { useEffect } from 'react'
import PageTitle from '../../components/misc/PageTitle'
import SBTable from '../../components/misc/SBTable'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { TableParams } from '../../models/TableParams'
import { initialQuery } from '../../models/helper'
import { Space, TablePaginationConfig } from 'antd'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { Control as ControlModel } from '../../models/Template'
import { extractSortDataFromSorterResult } from '../../helpers/TableHelper'
import { ColumnsType } from 'antd/lib/table'
import i18n from '../../i18n/setupLocalization'
import LocalizationKeys from '../../i18n/LocalizationKeys'
import {
  getControls,
  selectControls,
  setControlQuery,
} from '../../store/ControlReducer'

export default function ControlsPage() {
  const dispatch = useAppDispatch()
  const controls = useAppSelector(selectControls())
  const { query, isLoading } = useAppSelector((state) => state.control)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const columns: ColumnsType<ControlModel> = [
    {
      key: 'name',
      title: i18n.t(LocalizationKeys.Misc.Form.Name),
      render: (record: ControlModel) => record.name,
    },
    {
      key: 'slug',
      title: i18n.t(LocalizationKeys.Misc.Form.Slug),
      render: (record: ControlModel) => record.slug,
    },
    {
      key: 'type',
      title: 'Type',
      render: (record: ControlModel) => record.type,
    },
    {
      key: 'placeholder',
      title: 'Placeholder',
      render: (record: ControlModel) => record.placeholder,
    },
    {
      key: 'default_code',
      title: 'Default code',
      render: (record: ControlModel) => record.defaultCode,
    },
    {
      key: 'actions',
      title: 'Actions',
      render: (record: ControlModel) => (
        <Space direction="horizontal">
          <Actions
            entity={SBRMType.control}
            entityId={record.id}
            actions={['delete']}
          />
        </Space>
      ),
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<ControlModel> | SorterResult<ControlModel>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters,
      ...extractSortDataFromSorterResult<ControlModel>(sorter),
    }
    dispatch(setControlQuery(newQuery))
    dispatch(getControls(newQuery))
  }

  useEffect(() => {
    if (SBRMIsOpen) {
      return
    }

    // Trigger on init and when SBRM is closed
    const baseQuery: TableParams = {
      ...initialQuery,
    }
    dispatch(setControlQuery(baseQuery))
    dispatch(getControls(baseQuery))
  }, [dispatch, SBRMIsOpen])

  return (
    <>
      <PageTitle
        title="Controls"
        toolbar={<Actions entity={SBRMType.control} actions={['create']} />}
      />
      <SBTable
        entity={SBRMType.control}
        columns={columns}
        rowKey={(r) => r.id}
        dataSource={controls}
        pagination={query.pagination}
        loading={isLoading && !controls.length}
        onChange={handleTableChange}
      />
    </>
  )
}

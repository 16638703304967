import PageTitle from '../../components/misc/PageTitle'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'

export default function TemplatesPage() {
  return (
    <>
      <PageTitle title="Signature" />
    </>
  )
}

import { Typography } from 'antd'

const { Title, Paragraph } = Typography

const DataPrivacy = () => {
  return (
    <>
      <Title level={2}>Politique de Confidentialité</Title>
      <Paragraph>Lorem ipsum dolor sit amet</Paragraph>
    </>
  )
}

export default DataPrivacy

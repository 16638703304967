import { useEffect } from 'react'
import PageTitle from '../../components/misc/PageTitle'
import SBTable from '../../components/misc/SBTable'
import { Actions } from '../../modules/sbrm/components/Actions'
import { SBRMType } from '../../modules/sbrm/SBRMModel'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  getTemplates,
  selectTemplates,
  setTemplateQuery,
} from '../../store/TemplateReducer'
import { TableParams } from '../../models/TableParams'
import { initialQuery } from '../../models/helper'
import { TablePaginationConfig } from 'antd'
import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { Template as TemplateModel } from '../../models/Template'
import { extractSortDataFromSorterResult } from '../../helpers/TableHelper'
import { ColumnsType } from 'antd/lib/table'
import i18n from '../../i18n/setupLocalization'
import LocalizationKeys from '../../i18n/LocalizationKeys'

export default function TemplatesPage() {
  const dispatch = useAppDispatch()
  const templates = useAppSelector(selectTemplates())
  const { query, isLoading } = useAppSelector((state) => state.template)
  const { isOpen: SBRMIsOpen } = useAppSelector((state) => state.SBRM)

  const columns: ColumnsType<TemplateModel> = [
    {
      key: 'name',
      title: i18n.t(LocalizationKeys.Misc.Form.Name),
      render: (template: TemplateModel) => template.name,
    },
    {
      key: 'slug',
      title: i18n.t(LocalizationKeys.Misc.Form.Slug),
      render: (template: TemplateModel) => template.slug,
    },
  ]

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<TemplateModel> | SorterResult<TemplateModel>[]
  ) => {
    const newQuery = {
      ...query,
      pagination,
      filters,
      ...extractSortDataFromSorterResult<TemplateModel>(sorter),
    }
    dispatch(setTemplateQuery(newQuery))
    dispatch(getTemplates(newQuery))
  }

  useEffect(() => {
    if (SBRMIsOpen) {
      return
    }

    // Trigger on init and when SBRM is closed
    const baseQuery: TableParams = {
      ...initialQuery,
    }
    dispatch(setTemplateQuery(baseQuery))
    dispatch(getTemplates(baseQuery))
  }, [dispatch, SBRMIsOpen])

  return (
    <>
      <PageTitle
        title="Templates"
        toolbar={<Actions entity={SBRMType.template} actions={['create']} />}
      />
      <SBTable
        entity={SBRMType.template}
        columns={columns}
        rowKey={(r) => r.id}
        dataSource={templates}
        pagination={query.pagination}
        loading={isLoading && !templates.length}
        onChange={handleTableChange}
      />
    </>
  )
}

import { Typography } from 'antd'

const { Title, Paragraph } = Typography

const LegalNotice = () => {
  return (
    <>
      <Title level={2}>MENTIONS LEGALES</Title>
      <Paragraph>Lorem ipsum dolor sit amet</Paragraph>
    </>
  )
}

export default LegalNotice
